import { ready } from './utils/ready';
import '../scss/app.scss';
ready(function () {
    function letsSpin(status) {
        if (status === void 0) { status = ''; }
        var x = 1024;
        var y = 9999;
        var deg = Math.floor(Math.random() * (x - y)) + y;
        var pie = document.querySelector('.main > div > div:first-child');
        if (pie) {
            switch (status) {
                case 'that':
                    pie.style.transform = "rotate(2700deg)";
                    break;
                case 'this':
                    pie.style.transform = "rotate(3300deg)";
                    break;
                case 'off':
                    pie.style.transform = "rotate(3000deg)";
                    break;
                case 'me':
                    pie.style.transform = "rotate(2520deg)";
                    break;
                case 'it':
                    pie.style.transform = "rotate(2760deg)";
                    break;
                case 'you':
                    pie.style.transform = "rotate(2820deg)";
                    break;
                default:
                    pie.style.transform = "rotate(".concat(deg, "deg)");
            }
        }
    }
    var buttons = document.querySelectorAll('button');
    var currentLocation = window.location.pathname
        .replace('/', '')
        .replace('.html', '');
    if (currentLocation) {
        letsSpin(currentLocation);
    }
    buttons.forEach(function (button) {
        return button.addEventListener('click', function () { return letsSpin(); });
    });
    var elements = document.querySelectorAll('[data-type-effect]');
    elements.forEach(function (element) {
        var cursorSpan = element.querySelector('.cursor');
        var typedTextSpan = element.querySelector('.typed-text');
        var typingDelay = parseFloat(element.getAttribute('data-type-effect-delay')) || 200;
        var erasingDelay = parseFloat(element.getAttribute('data-type-effect-erase-delay')) || 100;
        var newTextDelay = parseFloat(element.getAttribute('data-type-effect-new-text-delay')) ||
            2000;
        var textArray = element.getAttribute('data-type-effect');
        var stringArray;
        var textArrayIndex = 0;
        var charIndex = 0;
        function erase() {
            if (charIndex > 0) {
                if (!cursorSpan.classList.contains('typing'))
                    cursorSpan.classList.add('typing');
                typedTextSpan.textContent = stringArray[textArrayIndex].substring(0, charIndex - 1);
                charIndex--;
                setTimeout(erase, erasingDelay);
            }
            else {
                cursorSpan.classList.remove('typing');
                textArrayIndex++;
                if (textArrayIndex >= stringArray.length)
                    textArrayIndex = 0;
                setTimeout(type, typingDelay + 1100);
            }
        }
        function type() {
            if (charIndex < stringArray[textArrayIndex].length) {
                if (!cursorSpan.classList.contains('typing'))
                    cursorSpan.classList.add('typing');
                typedTextSpan.textContent +=
                    stringArray[textArrayIndex].charAt(charIndex);
                charIndex++;
                setTimeout(type, typingDelay);
            }
            else {
                cursorSpan.classList.remove('typing');
                setTimeout(erase, newTextDelay);
            }
        }
        if (textArray) {
            stringArray = textArray.split(',');
            setTimeout(type, newTextDelay + 250);
        }
    });
});
